import React, { useEffect, useState } from "react";
import "./styles.css";
import ReactFlagsSelect from "react-flags-select";
import CountryCodeSelect from "./CountryCodeSelect/index.tsx";
import PhoneInputWithCountrySelect from "react-phone-number-input";
import "react-phone-number-input/style.css";
import es from 'react-phone-number-input/locale/es'


export interface InputProps {
  value: string;
  onChange: (value: string) => void;
  placeholder?: string;
  name?: string;
}

const PhoneInput: React.FC<InputProps> = ({
  value,
  onChange,
  placeholder,
  name,
}) => {
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const [selected, setSelected] = useState<string>("ES");
  const [phoneValue, setPhoneValue] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (value) {
      setPhoneValue(value);
    }
  }, [value]);

  useEffect(() => {
    if (phoneValue) {
      onChange(phoneValue);
    }
  }, [phoneValue]);

  return (
    <div className={`phone-input-wrapper ${isFocused && "focus"}`}>
      <PhoneInputWithCountrySelect
        placeholder="Teléfono"
        value={phoneValue}
        onChange={setPhoneValue}
        defaultCountry="ES"
        labels={es}
      />
    </div>
  );
};

export default PhoneInput;
