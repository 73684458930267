// apiClient.js
import axios from "axios";
// Constants
const BASE_URL = 'https://api.34.175.239.24.nip.io/api/';
const API_TIMEOUT = 10000;
const AUTH_TOKEN_KEY = "authToken";

let authToken: string | null = null;

// Utility Functions

// Load the token from local storage when the module is loaded
const getAuthToken = () => {
  return localStorage.getItem(AUTH_TOKEN_KEY);
};

// Set the token in local storage and update the in-memory variable
export const setAuthToken = (token) => {
  if (token) {
    authToken = token;
    localStorage.setItem(AUTH_TOKEN_KEY, token);
  } else {
    authToken = null;
    localStorage.removeItem(AUTH_TOKEN_KEY);
  }
};

// Axios Instance
export const apiClient = axios.create({
  baseURL: BASE_URL,
  timeout: API_TIMEOUT,
});

// Request Interceptor
// apiClient.interceptors.request.use(
//   async (config) => {
//     if (!authToken) {
//       authToken = getAuthToken();
//     }
//     console.log(`Bearer ${authToken}`);
//     config.headers.Authorization = `Bearer ${authToken}`;
//     return config;
//   },
//   (error) => {
//     // logError({ message: 'Error setting request headers', error });
//     return Promise.reject(error);
//   }
// );

// Request Logger
apiClient.interceptors.request.use((request) => {
  const { data, headers, method, url } = request;
  console.log(`[API_REQUEST] ${url}`, data, {
    method,
    url,
  });

  return request;
});

// Response Logger
apiClient.interceptors.response.use(
  (response) => {
    const { data, headers, status, config } = response;
    console.log(`[API_RESPONSE] ${config?.url}`, {
      data,
      status,
    });

    return response;
  },
  (error) => {
    console.log(
      `[API_RESPONSE_ERROR] ${error.config?.url}`,
      JSON.stringify(error, null, 4),
      error.message,
      error.response?.data
    );
    return Promise.reject(error);
  }
);

// Response Error Handler
apiClient.interceptors.response.use(
  async (response) => {
    return response;
  },
  async (error) => {
    if (error.response?.status === 401) {
      console.log("[TOKEN EXPIRED]", authToken);
      await setAuthToken(null);
    }
    return Promise.reject(error);
  }
);

// Function to update the auth token in memory and local storage
export const updateAuthToken = async (token) => {
  await setAuthToken(token);
};
