import React from "react";
import "./styles.css";
import { Booking, Restaurant, StepForDisplay } from "../../types/types";
import { CreateManagerBooking } from "../../hooks/useBookingForm/useBookingForm";

interface ConfirmBookingProps {
  stepsForDisplay: StepForDisplay[];
  restaurant: Restaurant;
  name: string;
  booking: CreateManagerBooking;
}

const ConfirmBooking: React.FC<ConfirmBookingProps> = ({
  stepsForDisplay,
  restaurant,
  name,
  booking,
}) => {
  return (
    <div className="animated-view">
      <div>
        <p className="inner-top-text">{`¡Hola ${
          name.split(" ")[0]
        }! ¿Todo correcto?`}</p>
        <span className="inner-top-text">{`Confirma tu reserva en:`}</span>
      </div>
      <div>
        <p className="inner-restaurant-name">{`${restaurant.name}`}</p>
        <p className="inner-restaurant-address">{`${restaurant.address.address}, ${restaurant.address.addressNumber}, ${restaurant.address.zipCode} ${restaurant.address.location.city}, ${restaurant.address.location.province}`}</p>
      </div>
      <div className="scroll-container">
        {stepsForDisplay.slice(0, -1).map((step, index) => {
          return step.name === "Cliente" ? (
            <>
              <div key={index} className="step-box">
                <span className="inner-step-text">{"Nombre"}</span>
                <span className="inner-description-text">
                  {booking.client?.name}
                </span>
              </div>

              <div key={index} className="step-box">
                <span className="inner-step-text">{"Email"}</span>
                <span className="inner-description-text">
                  {booking.client?.email}
                </span>
              </div>

              <div key={index} className="step-box">
                <span className="inner-step-text">{"Teléfono"}</span>
                <span className="inner-description-text">
                  {booking.client?.phoneNumber}
                </span>
              </div>
            </>
          ) : (
            <div key={index} className="step-box">
              <span className="inner-step-text">{step.name}</span>
              <span className="inner-description-text">{step.label}</span>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ConfirmBooking;
